<template>
  <div class="shop">
    <!-- tab栏 -->
    <div class="information">
      <van-tabs sticky
        @click="onClick">
        <van-tab v-for="dyitem in dylist"
          :key="dyitem.index"
          :title="dyitem.Title + '单元'"
          :name="dyitem.BUId">
          <div class="tsrq"
            style="line-height: 30px;">
            <span style="border-color: #edb3b2;">常住户</span>
            <span style="border-color: #a8daf6">租赁户</span>
            <span style="border-color: #dae483">空挂户</span>
            <span style="border-color: #fef8b1">空房户</span>
            <span style="border-color: #f393cc">公租</span>
            <span style="border-color: #fcef00">廉租</span>
            <span style="border-color: #11e67b">商铺</span>
            <span style="border-color: #8a8a8a">无数据</span>
          </div>
          <div class="renkouinfo"
            style="line-height: 30px;">
            <span style="margin-right:38px;">总户数：{{ buildInfo.roomTotal }}</span>
            <span>总人口数：{{ buildInfo.householdTotal }}</span>
          </div>
          <div class="renkouinfo"
            style="line-height: 30px;">
            <span>车辆总数：{{ buildInfo.cardTotal }}</span>
            <span>党员人数：{{ buildInfo.partyMemberTotal }}</span>
          </div>
          <div class="roomtype">
            <span>
              <i class="iconfont icon-dangyuan"
                style="color: red;"></i>党员
            </span>
            <span>
              <i class="iconfont icon-weixin1"
                style="color: #1afa29"></i>会员
            </span>
            <span>
              <i class="iconfont icon-chongwu1"
                style="color: #1296db"></i>宠物
            </span>
            <span>
              <i class="iconfont icon-cheliang1"
                style="color: #d81e06"></i>车辆
            </span>
            <span>
              <i class="iconfont icon-junren"
                style="color: rgb(230, 90, 90)"></i>退役军人
            </span>
          </div>
          <van-loading size="24px"
            vertical
            v-if="loading">加载中...</van-loading>
          <div class="ldcontent"
            v-if="!loading">
            <!-- {{fangwuData}} -->
            <div class="loucent"
              v-for="floor in fangwuData"
              :key="floor.index"
              style="display: flow-root">
              <!-- <div>123321{{floor}}</div> -->
              <!-- v-show="floor.BUId == dyBuid" -->
              <!-- <div v-show="floor.BUId == dyBuid"> -->
              <div class="louceng"
                style="display: none"
                v-if="floor.BFNo == undefined || floor.BFNo.length <= 0"></div>
              <div class="louceng"
                v-else>第{{ floor.BFNo }}层</div>
              <div style="float: left; width: 92%">
                <div style="
                    width: 28%;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 25px;
                    float: left;
                    margin: 2%;
                  "
                  v-for="(room, key) in floor.fimillylist"
                  @click="zhuhuList(room)"
                  :class="
                    room.FamilySize <= 0 ? 'kcent' : 'cent' + room.FamilyKind
                  "
                  :key="'room' + key"
                  v-show="room.BUId == dyBuid">
                  <div :class="room.IsShowShop == 1 ? 'showshop' : ''">
                    <i class="room">{{ room.BRNO }}</i>
                    <i class="roomNum"
                      v-if="room.FamilySize > 0">共{{ room.FamilySize }}人</i>
                    <i class="roomNum"
                      v-else>无数据</i>
                    <i class="iconfont icon-weixin1"
                      style="color: #1afa29"
                      v-if="room.IsMember == 1"></i>
                    <i class="iconfont icon-dangyuan"
                      style="color: red"
                      v-if="room.IsPartyMember == 1"></i>
                    <i class="iconfont icon-chongwu1"
                      style="color: #1296db"
                      v-if="room.IsHavePets == 1"></i>
                    <i class="iconfont icon-cheliang1"
                      style="color: #d81e06"
                      v-if="room.IsHaveCar == 1"></i>
                    <i class="iconfont icon-junren"
                      style="color: rgb(230, 90, 90)"
                      v-if="room.IsVeterans == 1"></i>
                  </div>
                </div>
              </div>
            </div>
            <van-empty v-if="wushuju"
              description="暂无数据" />
          </div>
          <!-- </div> -->
          <!-- </van-list> -->
        </van-tab>
      </van-tabs>
      <!-- <div class="addroom">
          <van-button type="primary" block @click="roomadd">新增房屋</van-button>
      </div> -->
    </div>
  </div>
</template>
<script>
import {
  GetBuildUnitPage,
  GetBuildRoomPage,
  GetResidentListByFamily,
  GetBuildRoomList,
  GetBuildFamilyPage,
  GetBuildInfo,
} from "@/api/wangge";
import { getwgToken, setwgToken } from "@/utils/auth";
export default {
  data () {
    return {
      riKind: 0, //tab栏
      dylist: {}, //单元
      dyBuid: "",
      list: {},
      fangwuData: [],
      listfrom: {
        page: 1,
        status: 1,
        limit: 10,
        bId: "",
      }, //查询传参
      buildInfo: {},
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      wushuju: false,
      show: false,
      jiating: '',
      ldfrom: {
        page: 1,
        limit: 10,
        rId: "",
        status: 1,
      },
      fwfrom: {
        // page: 1,
        // limit: 999,
        bId: "",
        status: 1,
        // buId: "",
      },
      ldlist: {},
    };
  },
  created () {
    this.getList();
    this.getLdList();
  },
  methods: {
    // 获取楼栋详情
    getLdList () {
      console.log(this.$route);
      GetBuildInfo({ accToken: getwgToken(), bId: this.$route.params.BId })
        .then((res) => {
          if (res.data.code == 0) {
            this.buildInfo = res.data;
            console.log(this.buildInfo);
          }
        })
        .catch(() => { });
    },
    // 获取单元列表
    getList () {
      //   this.listfrom.riKind = this.riKind;
      this.listfrom.bId = this.$route.params.BId;
      this.listfrom.status = 1;
      this.listfrom.accToken = getwgToken();
      GetBuildUnitPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.dylist = {};
              this.wushuju = true;
            } else {
              this.wushuju = false;
              this.dylist = res.data.data;
              this.dyBuid = res.data.data[0].BUId;
              console.log(this.dylist);
              // this.fwList(this.dylist[0].BUId);
              this.fwList(this.dylist[0].BId);
              console.log(this.dylist[0]);
              //   for (var i = 0; i < this.dylist.length; i++) {
              //     var item = this.dylist[i].IssTime;
              //     item = this.dataFormat(item);
              //     this.dylist[i].IssTime = item;
              //     console.log();
              //     this.fwList()
              //   }
            }
          }
        })
        .catch(() => { });
    },
    // 新增房屋
    roomadd () {
      // this.$router.push("roomAdd");
      var path = "/wangge/danyuan/addroom/0";
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // 获取房屋列表
    fwList (buId) {
      this.loading = true;
      var thin = this;
      console.log(buId);
      //   this.listfrom.riKind = this.riKind;
      // this.fwfrom.buId = buId;
      this.fwfrom.bId = buId;
      this.fwfrom.accToken = getwgToken();
      // GetBuildFamilyPage(this.fwfrom)
      GetBuildRoomList(this.fwfrom)
        .then((res) => {
          this.loading = false;
          // if (res.data.code == 0) {
          //   if (res.data.count == 0) {
          //     // this.hidden = true;
          //     this.finished = true;
          //     this.list = {};
          //   } else {
          //     this.list = res.data.data;
          //     this.total = res.data.count;
          //     this.finished = true;
          //     // this.hidden = false;
          //     for (var i = 0; i < this.list.length; i++) {
          //       var item = this.list[i].IssTime;
          //       item = this.dataFormat(item);
          //       this.list[i].IssTime = item;
          //     }
          //   }
          // }

          if (res.data.code == 0) {
            var list = res.data.data;
            // console.log(list);
            var build = [],
              builds = [],
              buids = [],
              buildlist = [];
            for (let i = 0; i < list.length; i++) {
              console.log(list[i]);
              builds.push(list[i].BFNo);
              buids.push(list[i].BUId);
            }
            console.log(buids);
            // 返回楼层号 并排序
            build = thin.unique(builds);
            for (let i = build.length; i >= 0; i--) {
              buildlist.push({
                BFNo: build[i],
                BUId: buids[i],
                fimillylist: [],
              });
            }

            for (let f = 0; f < list.length; f++) {
              for (let j = 0; j < buildlist.length; j++) {
                var io = buildlist[j];
                if (list[f].BFNo == io.BFNo) {
                  io.fimillylist.push({
                    BId: list[f].BId,
                    BFNo: list[f].BFNo,
                    BFId: list[f].BFId,
                    BRNO: list[f].BRNO,
                    BRId: list[f].BRId,
                    BUId: list[f].BUId,
                    IsHavePets: list[f].IsHavePets,
                    IsHaveCar: list[f].IsHaveCar,
                    IsMember: list[f].IsMember,
                    IsPartyMember: list[f].IsPartyMember,
                    IsVeterans: list[f].IsVeterans,
                    FamilySize: list[f].FamilySize,
                    Householder: list[f].Householder,
                    BFaId: list[f].BFaId,
                    FamilyKind: list[f].FamilyKind,
                    IsShowShop: list[f].IsShowShop,
                    OrganCode: list[f].OrganCode,
                    IsShowShop: list[f].IsShowShop,
                  });
                }
              }
            }

            // 验证请求是否过期 否则不赋值
            // if (list.length && list[0]["BId"] == thin.buildInfo["BId"]) {
            thin.fangwuData = buildlist;
            console.log(thin.fangwuData);
            // }
            console.log("拼接住户列表");
            console.log(buildlist);
          }
        })
        .catch(() => { });
    },
    unique: function (arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr.sort(function (a, b) {
        return a - b;
      });
    },
    zhuhuList: function (row) {
      console.log(row);
      this.BRId = JSON.stringify(row.BRId);
      this.ShopShow = JSON.stringify(row.IsShowShop);
      this.BFId = row.BFId;
      this.BId = row.BId;
      this.BUId = row.BUId;
      this.Code = Number(row.OrganCode);
      console.log(this.$route.params);
      this.RId = this.$route.params.RId;
      this.$router.push("/wangge/danyuan/jiating/" + this.BRId + "/" + this.ShopShow + "/" + this.RId + "/" + this.Code + "/" + this.BFId + "/" + this.BId + "/" + this.BUId);
      // this.$router.push({
      //   name: "jiating",
      //   query: { 
      //     BRId: this.itemmark,
      //     ShopShow: this.shopShow,
      //     GId: this.gid,
      //     Code: this.code,
      //   },
      // });
    },
    //跳转到公共页面
    // zhuhuList(path) {
    //   console.log(path.BRId);
    //   this.jiating = '/wangge/jiating/';
    //   console.log(this.jiating);
    //   console.log();
    //   if (window.__wxjs_environment === "miniprogram") {
    //     window.wx.miniProgram.navigateTo({
    //       // url: "../public/goto?path=" + path,
    //       url: "../public/goto?path="+ this.jiating + path.BRId,
    //     });
    //   } else {
    //     this.$router.push(path);
    //   }
    // },
    // 获取住户列表
    zhList (bFaId) {
      console.log(bFaId);
      //   this.listfrom.riKind = this.riKind;
      this.zhfrom.bFaId = bFaId;
      this.zhfrom.accToken = getwgToken();
      GetResidentListByFamily(this.fwfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.hidden = false;
              for (var i = 0; i < this.list.length; i++) {
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item;
              }
            }
          }
        })
        .catch(() => { });
    },
    showPopup (RId) {
      console.log(RId);
      this.show = true;
      this.ldList(RId);
    },
    onClose () {
      this.setData({ show: false });
    },
    // 下拉刷新
    // onLoad() {
    //   console.log(123);
    //   this.listfrom.page = this.listfrom.page + 1;
    //   this.getList();
    // },
    // tan点击切换事件
    onClick (e) {
      console.log(123123);
      console.log(e);
      this.dyBuid = e;
    },
    // 搜索
    onSearch () {
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
};
</script>
<style scoped>
.shopTitle {
  color: #333;
  padding: 10px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  background: rgb(246, 243, 243);
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>
<style scoped>
.tsrq {
  font-size: 16px;
  margin-top: 12px;
  margin: 25px 25px 0px 25px;
}
.tsrq span {
  border-left-color: #fff;
  border-left-width: 15px;
  border-left-style: solid;
  padding-left: 5px;
  line-height: 15px;
  display: inline-block;
  margin-right: 5px;
}
.renkouinfo {
  font-size: 17px;
  margin: 0 25px;
}
.renkouinfo span {
  margin-right: 30px;
}
/deep/.van-tabs__line {
  background-color: #617bfa !important;
}
/deep/.van-tab {
  font-size: 16px !important;
}
/deep/.van-tab--active {
  font-size: 16px !important;
}
.cent1 {
  background: #edb3b2;
  color: #fff;
}
.cent2 {
  background: #a8daf6;
  color: #fff;
}
.cent3 {
  background: #fef8b1;
  color: #333;
}
.cent4 {
  background: #f393cc;
  color: #333;
}
.cent5 {
  background: #fcef00;
  color: #fff;
}
.cent6 {
  background: #11e67b;
  color: #333;
}
.louceng {
  width: 15px;
  /* background-color: rgb(160, 172, 230); */
  border-radius: 5px;
  text-align: center;
  float: left;
  margin: 5px 0 10px 10px;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-line;
}
.loucent {
  margin-top: 2px;
  font-size: 14px;
  white-space: nowrap;
}
.ldcontent {
  overflow: auto;
}
.loucent span.cent1 {
  background: #00e3fe;
  color: #333;
}
.loucent span.cent2 {
  background: #a0d9f6;
  color: #333;
}
.loucent span.cent3 {
  background: #d9e583;
  color: #333;
}
.loucent span.cent4 {
  background: #fff9b1;
  color: #333;
}
.loucent span.cent5 {
  background: #e40083;
  color: #333;
}
.loucent span.cent6 {
  background: #fef000;
  color: #333;
}
.showshop {
  background: #11e67b;
  color: #333;
  border-radius: 5px;
}
.loucent .d1 {
  line-height: 20px;
  text-align: center;
  font-size: 16px;
}
.kcent {
  background: #8a8a8a;
  color: white;
}
.loucent i {
  font-style: normal;
  font-size: 14px;
}
.loucent .room {
  display: block;
  padding-top: 8px;
  font-size: 16px;
}
.loucent .roomNum {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 16px;
}
.information {
  padding-bottom: 100px;
}
.addroom {
  padding: 10px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
}
.roomtype {
  margin: 0 25px;
}
.roomtype span {
  margin-right: 14px;
  line-height: 30px;
  display: inline-block;
}
.roomtype i {
  font-size: 20px;
  margin-right: 3px;
}
</style>